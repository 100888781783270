<body>
<!-- contact-form.component.html -->
<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <label for="name">Name</label>
    <input type="text" id="name" formControlName="name">

    <label for="email">Email</label>
    <input type="email" id="email" formControlName="email">

    <label for="message">Message</label>
    <textarea id="message" formControlName="message"></textarea>

    <button type="submit">Send</button>
  </form>

  <p *ngIf="contactForm.controls['name'].invalid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched)">Name is required</p>

</body>
