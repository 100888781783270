import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent {
  contactForm!: FormGroup;

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.contactForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('name', this.contactForm.value.name!);
    formData.append('email', this.contactForm.value.email!);
    formData.append('message', this.contactForm.value.message!);

    this.http.post('https://your-server-url.com/send-email', formData)
      .subscribe(response => {
        console.log(response);
        alert('Thank you for your message!');
        this.contactForm.reset();
      }, error => {
        console.error(error);
        alert('An error occurred while sending your message. Please try again later.');
      });
  }
}
