import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { interval } from 'rxjs';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  time: Date | undefined;
  isSidebarOpen = false;

  ngOnInit() {
    this.updateTime();
  }

  private updateTime() {
    interval(1000).subscribe(() => {
      this.time = new Date();
    });
  }
}
