import { Component } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@Component({
  selector: 'app-resume',
  template: `
  <div>
    <pdf-viewer [src]="pdfSrc"
                [render-text]="true"
                [original-size]="false"
                [fit-to-page]="true"
                style="display: block; width: 100%; height: 100vh;"
    ></pdf-viewer>
    <a [href]="pdfSrc" download="CV_marek_gajewski.pdf">
      <button>Download</button>
    </a>
  </div>
  `
})
export class ResumeComponent {
  pdfSrc = 'assets/CV_marek_gajewski.pdf'
}
