
<body>
  <section>
    <h2>Professional Experience</h2>

    <h3>Amazon - Supply Chain Execution (Software Engineer Intern)</h3>
    <p>Location: London, UK | Duration: 03/2023 - 12/2023</p>
    <ul>
        <li>Collaborated on a cluster optimization project using Python, SQL, and Amazon’s Cloud Development Kit (CDK). Managed the entire infrastructure, a crucial step in project success.</li>
        <li>Migrated an operation-critical, outdated service to AWS Lightsail from EC2 clusters. Rewrote the codebase in Go, resulting in a 60% reduction in operational costs and a 25% performance boost.</li>
        <li>Designed, developed, and maintained ETL pipelines using AWS Redshift, saving approximately 10 hours weekly of manual work.</li>
        <li>Utilized various AWS services for data storage, processing, and analytics, enhancing overall solution performance, security, and accessibility.</li>
        <li>Led ELK solutions for query analysis, providing the Automation team with valuable insights into solution performance.</li>
    </ul>

    <h3>Logiblox (Full Stack Developer)</h3>
    <p>Location: Zurich, Switzerland | Duration: 05/2022 - 03/2023</p>
    <ul>
        <li>Developed and maintained a Python-based backend, adding vital features to the entire system.</li>
        <li>Created scalable front-end applications using Angular, significantly enhancing the platform’s user experience.</li>
        <li>Built and deployed containerized applications with Docker, reducing build time in GitLab significantly.</li>
        <li>Developed comprehensive test suites for Python and TypeScript applications, covering 80% of our system.</li>
    </ul>

    <h3>Softrender (Software Developer Intern)</h3>
    <p>Location: Gdańsk, Poland | Duration: 05/2021 - 10/2021</p>
    <ul>
        <li>Created and maintained webpages using Angular frameworks, making backend functionalities accessible to end-users.</li>
        <li>Successfully delivered a functional and robust Spring Boot API in collaboration with the Java backend development team.</li>
        <li>Developed an extensive test suite for vital services using JUnit and Mockito, achieving a test coverage of 75%.</li>
        <li>Led the initiative to modernize and enhance the performance of legacy code through systematic refactoring.</li>
    </ul>

    <h3>Testlio (Mobile Software Tester - Remote, part-time)</h3>
    <p>Location: Tallinn, Estonia | Duration: 09/2018 - 01/2019</p>
    <ul>
        <li>Reviewed prototype applications and reported functional and linguistic errors.</li>
        <li>Contributed to projects such as Microsoft To-Do Planner and Fortuna Bet Systems.</li>
    </ul>

    <h2>Skills</h2>
    <ul>
        <li><strong>Programming Languages:</strong> Python, TypeScript, Go, Java</li>
        <li><strong>Web Development Frameworks:</strong> Angular, React</li>
        <li><strong>Cloud Platforms:</strong> AWS, Google Cloud</li>
        <li><strong>DevOps Tools:</strong> Docker, GitLab, GitHub, K8s</li>
        <li><strong>Project Management Software:</strong> Jira, Slack, Google Workspace</li>
        <li><strong>Leadership Skills:</strong> Mentoring and leading projects</li>
        <li><strong>Work Style:</strong> Thriving under pressure and within tight schedules</li>
    </ul>

    <h2>Education</h2>
    <ul>
        <li><strong>University of Aberdeen - Aberdeen</strong></li>
        <ul>
            <li>Bachelor of Science in Computer Science (2020 - 2024)</li>
            <li>Relevant Coursework: Software Engineering, Databases and Data Management, Artificial Intelligence, Machine Learning, Operating Systems, Distributed Systems, Security</li>
        </ul>
        <li><strong>Exchange at the University of Zurich - Zurich</strong></li>
        <ul>
            <li>Bachelor of Science in Computer Science (2022)</li>
            <li>Relevant Coursework: Software Testing, Algorithms, Computer Architecture, Professional Software Practice</li>
        </ul>
    </ul>

    <h2>Certificates</h2>
    <ul>
      <li>AWS Practicioner</li>
      <li>AWS Developer Assocaite</li>
      <li>Kuberneetes Fundamentals</li>
    </ul>

    <p class="footer">Feel free to <a href="/contact-form">reach out</a> if you have any questions or if you'd like to discuss potential collaborations.</p>
</section>
</body>
