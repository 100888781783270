import { Component, ViewChild } from '@angular/core';
import Typewriter from 't-writer.js';
@Component({
  selector: 'app-main-frame',
  templateUrl: './main-frame.component.html',
  styleUrls: ['./main-frame.component.scss']
})
export class MainFrameComponent {

  @ViewChild('tw') typewriterElement: any;

  ngAfterViewInit() {
    const target = this.typewriterElement.nativeElement;
    const writer = new Typewriter(target, {
      loop: true,
      typeColor: 'white'
    });
    writer.type('Hello, I am Marek Gajewski.').rest(500).start()
  }
}
