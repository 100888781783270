<header>
  <button class="menu-button" *ngIf="!isSidebarOpen" (click)="isSidebarOpen = !isSidebarOpen">Menu</button>

  <nav [class.open]="isSidebarOpen">
    <button class="close-button" (click)="isSidebarOpen = false">Close</button>
    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
    <a routerLink="/about-page" routerLinkActive="active">About Page</a>
    <a routerLink="/contact-form" routerLinkActive="active">Contact</a>
    <a href="/resume" routerLinkActive="active">Resume</a>
    <a routerLink="projects" routerLinkActive="active">Projects</a>
    <a routerLink="bullock" routerLinkActive="active">Bullock</a>
  </nav>

  <div class="current-time">{{ time | date: 'medium' }}</div>
</header>
